<template>
  <main>
    <SectionHero :variant="hero.variant" :class="hero.className">
      <OrganismHero :hero="hero" />
      <template #footerShort>
        <div
          class="flex flex-row -md:justify-between w-full md:gap-x-12 items-center md:h-full relative bottom-[-20px]"
        >
          <svg
            class="-md:w-[30px]"
            width="55"
            height="35"
            viewBox="0 0 55 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M54.9714 17.1104C54.9714 26.5606 47.3656 34.2206 37.985 34.2206C34.1706 34.2255 30.4675 32.9359 27.4844 30.5634C29.5119 28.9552 31.1494 26.9096 32.2734 24.5797C33.3975 22.2499 33.9791 19.6964 33.9731 17.1104C33.9781 14.5249 33.3975 11.9719 32.2744 9.64242C31.1504 7.31296 29.5139 5.26747 27.4864 3.65924C30.4685 1.28634 34.1706 -0.00387725 37.985 0.000254031C47.3656 0.000254031 54.9694 7.66212 54.9694 17.1104"
              fill="#282929"
            />
            <path
              d="M20.9978 17.1104C20.9928 14.5249 21.5734 11.9719 22.6975 9.64242C23.8205 7.31296 25.457 5.26747 27.4855 3.65924C24.5024 1.28648 20.8003 -0.00372754 16.9859 0.000253325C7.60526 0.000253325 0 7.66212 0 17.1104C0 26.5606 7.60526 34.2206 16.9859 34.2206C20.8003 34.2249 24.5024 32.9354 27.4855 30.5634C25.457 28.9552 23.8195 26.9095 22.6954 24.5797C21.5714 22.2499 20.9908 19.6964 20.9958 17.1104"
              fill="#282929"
            />
          </svg>
          <svg
            class="-md:w-[38px]"
            width="72"
            height="24"
            viewBox="0 0 72 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M28.6556 0.43335L25.0742 22.7325H30.8042L34.4333 0.43335H28.6556Z" fill="#282929" />
            <path
              d="M46.0319 9.35919C44.0266 8.35645 42.785 7.6402 42.785 6.58963C42.785 5.63472 43.8354 4.67972 46.1274 4.67972C47.9897 4.63199 49.3744 5.06166 50.425 5.53916L50.9501 5.77791L51.7142 0.955231C50.5682 0.525488 48.7536 0.000244141 46.5571 0.000244141C40.875 0.000244141 36.864 3.05619 36.8163 7.35371C36.7685 10.5529 39.6813 12.3197 41.8778 13.4179C44.1221 14.5161 44.886 15.2323 44.886 16.1873C44.886 17.6676 43.0715 18.3838 41.448 18.3838C39.1559 18.3838 37.9146 18.0496 36.0523 17.1901L35.2884 16.8559L34.4766 21.8694C35.8136 22.4902 38.2966 23.0156 40.9229 23.0632C46.987 23.0632 50.9025 20.0551 50.9501 15.4711C50.9025 12.8926 49.3744 10.9826 46.0319 9.35919Z"
              fill="#282929"
            />
            <path
              d="M66.4729 0.43335H62.0321C60.6474 0.43335 59.5968 0.815343 59.0239 2.29559L50.4766 22.7325H56.5409C56.5409 22.7325 57.5437 19.9629 57.7347 19.39C58.4031 19.39 64.2764 19.39 65.1358 19.39C65.279 20.1539 65.8042 22.7325 65.8042 22.7325H71.1523L66.4729 0.43335ZM59.3582 14.8537C59.8356 13.5645 61.6502 8.5985 61.6502 8.5985C61.6023 8.64623 62.1276 7.30928 62.414 6.44983L62.796 8.35974C62.796 8.35974 63.8943 13.7077 64.133 14.8059C63.2737 14.8537 60.504 14.8537 59.3582 14.8537Z"
              fill="#282929"
            />
            <path
              d="M20.2458 0.433167L14.6114 15.6653L13.9906 12.5615L11.9851 2.34314C11.6509 0.91066 10.6482 0.528668 9.3589 0.480918H0.0954991L0 0.91066C2.24424 1.48365 4.29745 2.34314 6.0642 3.34588L11.2211 22.6845H17.3331L26.3576 0.433167H20.2458Z"
              fill="#282929"
            />
          </svg>
          <svg
            class="-md:w-[48px]"
            width="89"
            height="25"
            viewBox="0 0 89 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.19537 0.00440408C8.98754 -0.00037897 11.3409 -0.214203 12.3377 3.17977C13.0095 5.46601 14.0784 9.21103 15.5464 14.4147H16.1441C17.7184 8.92856 18.7993 5.18364 19.3879 3.17977C20.3957 -0.24956 22.9135 0.00447389 23.9203 0.00447389L31.6915 0.00448346V24.391H23.7709V10.0196H23.2404L18.8254 24.391H12.8661L8.45105 10.0089H7.9196V24.391H0V0.00448346L8.19537 0.00440408ZM43.0646 0.00448346V14.3866H43.6962L49.0669 2.56028C50.1098 0.206816 52.3308 0.00448346 52.3308 0.00448346H59.9957V24.3911H51.9097V10.0089H51.278L46.0126 21.8352C44.9707 24.1781 42.6434 24.3911 42.6434 24.3911H34.9785V0.00448346H43.0646ZM87.943 11.5931C86.815 14.8174 83.2733 17.1266 79.3516 17.1266H70.8725V24.3911H63.1834V11.5931H87.943Z"
              fill="#282929"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M79.7235 0.00463867H62.7812C63.1844 5.4339 67.819 10.0817 72.6171 10.0817H88.4784C89.3929 5.56937 86.2423 0.00463867 79.7235 0.00463867Z"
              fill="#282929"
            />
          </svg>
          <svg
            class="-md:w-[70px]"
            width="181"
            height="30"
            viewBox="0 0 181 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24.6866 25.2182C30.1606 19.6356 30.1607 10.5847 24.6866 5.00227C19.2126 -0.580184 10.3375 -0.580184 4.86333 5.00228C-0.610692 10.5847 -0.610692 19.6356 4.86333 25.2182C10.3375 30.8006 19.2126 30.8006 24.6866 25.2182Z"
              fill="#282929"
            />
            <path
              d="M11.7656 19.1059L20.8432 9.84844C21.0675 9.61939 21.3339 9.43768 21.6271 9.3137C21.9203 9.18973 22.2346 9.12592 22.552 9.12592C22.8695 9.12592 23.1838 9.18973 23.477 9.3137C23.7702 9.43768 24.0365 9.61939 24.2609 9.84844H24.2661C24.7183 10.3114 24.9721 10.9382 24.9721 11.5917C24.9721 12.245 24.7183 12.8719 24.2661 13.3349L18.6115 19.1059C17.7032 20.0307 16.4721 20.55 15.1885 20.55C13.905 20.55 12.674 20.0307 11.7656 19.1059Z"
              fill="#EFF0F4"
            />
            <path
              d="M17.7831 11.113L8.70546 20.3704C8.48102 20.5995 8.21454 20.7812 7.92124 20.9052C7.62794 21.0291 7.31362 21.093 6.99607 21.093C6.67862 21.093 6.3642 21.0291 6.0709 20.9052C5.77759 20.7812 5.51112 20.5995 5.28667 20.3704C5.06212 20.1415 4.88383 19.8697 4.76233 19.5706C4.64072 19.2715 4.57812 18.951 4.57812 18.6271C4.57812 18.3034 4.64072 17.9827 4.76233 17.6836C4.88383 17.3845 5.06212 17.1128 5.28667 16.8839L10.9414 11.1173C11.8491 10.1932 13.0789 9.67384 14.3613 9.67304C15.6439 9.67224 16.8744 10.19 17.7831 11.113Z"
              fill="#EFF0F4"
            />
            <path
              d="M98.093 15.0244C99.9477 15.0244 101.232 14.1511 101.232 12.177C101.232 10.2029 99.9477 9.32969 98.093 9.32969H94.5058V15.0222L98.093 15.0244ZM92.631 7.77008H98.2973C100.825 7.77008 103.107 8.99672 103.107 12.1737C103.107 15.3326 100.906 16.4969 98.3373 16.4969H94.5058V22.3205H92.631V7.77008Z"
              fill="#282929"
            />
            <path
              d="M107.468 21.2601C109.608 21.2601 110.871 19.7435 110.871 17.6438V16.9789L107.895 17.2914C106.183 17.4568 105.262 18.1636 105.262 19.4116C105.265 20.4298 105.917 21.2601 107.468 21.2601ZM107.691 16.1271L110.87 15.8146V14.7814C110.87 13.5139 110.095 12.4946 108.343 12.515C106.896 12.515 105.755 13.1595 105.672 14.6352H104.021C104.104 12.1648 105.978 11.1853 108.34 11.1853C111.152 11.1853 112.64 12.5816 112.64 15.1347V22.3256H111.015V20.0808C110.363 21.6188 108.956 22.5747 106.939 22.5747C104.533 22.5747 103.494 21.2032 103.494 19.4782C103.493 17.4568 104.94 16.3977 107.691 16.1271Z"
              fill="#282929"
            />
            <path
              d="M124.672 11.4292H126.322V13.4925C126.913 12.0156 128.157 11.1853 129.807 11.1853C131.438 11.1853 132.619 11.9748 133.007 13.5547C133.598 12.0371 134.882 11.1853 136.553 11.1853C138.612 11.1853 139.936 12.4946 139.936 14.9682V22.3256H138.145V15.4408C138.145 13.7363 137.408 12.6762 135.944 12.6762C134.273 12.6762 133.192 14.0273 133.192 16.2721V22.3212H131.418V15.4418C131.418 13.7373 130.662 12.6772 129.195 12.6772C127.524 12.6772 126.464 14.0283 126.464 16.2732V22.3224H124.674L124.672 11.4292Z"
              fill="#282929"
            />
            <path
              d="M149.754 16.1881C149.713 13.9219 148.531 12.5084 146.718 12.5084C144.964 12.5084 143.663 13.7565 143.497 16.1881H149.754ZM146.719 11.1787C149.772 11.1787 151.528 13.0701 151.528 17.144V17.3932H143.478C143.621 19.9292 144.945 21.2384 146.862 21.2384C148.288 21.2384 149.246 20.4866 149.695 19.3674L151.387 19.4748C150.835 21.3662 149.267 22.5713 146.857 22.5713C143.779 22.5713 141.68 20.7207 141.68 16.8788C141.683 13.0497 143.802 11.1787 146.719 11.1787Z"
              fill="#282929"
            />
            <path
              d="M153.409 11.4279H155.058V13.5277C155.67 12.0519 156.994 11.1787 158.87 11.1787C161.193 11.1787 162.518 12.4676 162.518 15.128V22.319H160.728V15.7091C160.728 13.7349 159.973 12.6748 158.262 12.6748C156.261 12.6748 155.207 14.0883 155.207 16.2709V22.32H153.418V11.4279H153.409Z"
              fill="#282929"
            />
            <path
              d="M167.668 12.822V19.1848C167.668 20.5563 167.954 20.9935 169.037 20.9935C169.454 20.9863 169.869 20.9504 170.281 20.8861V22.3404C169.764 22.4424 169.238 22.4914 168.712 22.4865C166.633 22.4865 165.878 21.654 165.878 19.4726V12.822H163.633V11.4709H165.344C165.814 11.4709 165.997 11.2839 165.997 10.8264V9.03913H167.668V11.4289H170.278V12.8252L167.668 12.822Z"
              fill="#282929"
            />
            <path
              d="M173.154 19.1408C173.296 20.5951 174.355 21.2406 176.048 21.2406C177.637 21.2406 178.697 20.6585 178.697 19.5565C178.697 18.7466 178.106 18.2676 176.638 17.8111L174.335 17.1248C172.684 16.6264 171.848 15.6083 171.848 14.1314C171.848 12.0326 173.541 11.1809 175.762 11.1809C178.127 11.1809 180.001 12.1572 180.205 14.4031L178.574 14.5105C178.391 13.1389 177.23 12.5149 175.762 12.5149C174.458 12.4741 173.602 13.0519 173.602 14.0744C173.602 14.9477 174.212 15.446 175.333 15.779L177.656 16.4653C179.447 16.9851 180.47 17.8992 180.47 19.4791C180.47 21.4951 178.777 22.5757 176.005 22.5757C173.396 22.5757 171.705 21.5575 171.522 19.2289L173.154 19.1408Z"
              fill="#282929"
            />
            <path
              d="M123.045 11.4502L119.07 23.5872C118.333 25.9566 117.56 26.7471 115.482 26.7471C115.157 26.7471 114.745 26.7267 114.301 26.6849V25.2714C114.668 25.2918 115.055 25.3132 115.381 25.3132C116.481 25.3132 116.95 24.8976 117.439 23.3176L117.746 22.3413L113.709 11.4502H115.665L118.63 20.0428L121.126 11.4502H123.045Z"
              fill="#282929"
            />
            <path
              d="M35.2891 15.4019C35.2891 11.3302 38.2549 8.24655 42.3055 8.24655C45.5431 8.24655 48.0624 10.2818 48.8576 13.227H45.8917C45.2524 11.8038 43.9538 10.9349 42.2866 10.9349C39.7863 10.9349 38.0801 12.8124 38.0801 15.4019C38.0801 17.9916 39.7863 19.8691 42.2866 19.8691C43.9538 19.8691 45.2524 19.0001 45.8917 17.577H48.8576C48.0624 20.521 45.5431 22.5574 42.3055 22.5574C38.2549 22.5564 35.2891 19.4738 35.2891 15.4019Z"
              fill="#282929"
            />
            <path d="M53.0483 7.77008H50.2762V22.3205H53.0483V7.77008Z" fill="#282929" />
            <path
              d="M57.4243 16.8853C57.4243 18.6844 58.6272 19.8701 60.1775 19.8701C61.7278 19.8701 62.9305 18.6886 62.9305 16.8853C62.9305 15.1066 61.7278 13.9005 60.1775 13.9005C58.6272 13.9005 57.4243 15.1034 57.4243 16.8853ZM65.7447 16.8853C65.7447 20.1666 63.3023 22.5584 60.1816 22.5584C57.0799 22.5584 54.6386 20.1472 54.6386 16.8853C54.6386 13.6051 57.12 11.2121 60.1816 11.2121C63.258 11.2121 65.7395 13.5997 65.7395 16.8853H65.7447Z"
              fill="#282929"
            />
            <path
              d="M77.191 11.4502V22.3199H74.4189V20.8161C73.6437 21.9224 72.5968 22.555 71.1245 22.555C68.779 22.555 67.3256 20.9149 67.3256 18.2663V11.4502H70.0976V17.6573C70.0976 19.0804 70.6242 19.8709 72.0555 19.8709C73.5868 19.8709 74.42 18.9419 74.42 17.1632V11.4502H77.191Z"
              fill="#282929"
            />
            <path
              d="M86.9469 16.8846C86.9469 15.106 85.7641 13.8998 84.2149 13.8998C82.6655 13.8998 81.525 15.1027 81.525 16.8846C81.525 18.6837 82.6835 19.8694 84.2191 19.8694C85.7641 19.8694 86.9469 18.6837 86.9469 16.8846ZM89.7189 7.77008V22.3205H86.9469V20.8576C86.2096 21.8854 85.0858 22.5579 83.5935 22.5579C80.8214 22.5579 78.7287 20.225 78.7287 16.8846C78.7287 13.5238 80.8414 11.2114 83.5735 11.2114C84.234 11.1982 84.8874 11.352 85.4755 11.659C86.0635 11.9659 86.568 12.4166 86.9438 12.9708V7.77008H89.7189Z"
              fill="#282929"
            />
          </svg>
        </div>
      </template>
    </SectionHero>
    <SectionDefault>
      <Builder :blocks="blocks" />
    </SectionDefault>
  </main>
</template>

<script>
import Builder from '../components/builder/Builder'
import pageBuilder from '../components/mixins/pageBuilder.js'
import OrganismHero from '../components/organisms/layouts/OrganismHero'
import SectionDefault from '../components/templates/layouts/SectionDefault'
import SectionHero from '../components/templates/layouts/SectionHero'

const mixinPageBuilder = pageBuilder({
  pageUrl: 'payment',
})

export default {
  name: 'Payment',
  components: {
    Builder,
    SectionDefault,
    SectionHero,
    OrganismHero,
  },
  mixins: [mixinPageBuilder],
  meta: {
    theme: 'dark',
  },
  data: c => {
    return {
      hero: {
        variant: 'dark-parallax',
        className: '!bg-gray-600 border-b-[2px] border-gray-100',
        title1: 'способы',
        title2: 'оплаты',
        breadcrumbs: c.$breadcrumbs.payment(),
      },
      direction: [{ text: 'Москва' }, { text: 'Москва 2' }],
      clubItems: [{ text: 'ENCORE СИТИ' }, { text: 'ENCORE СИТИ 2' }],
    }
  },
}
</script>
